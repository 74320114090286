import React from "react"
import SEO from "../../../components/seo"
// import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import TwoColumn from "../../../components/two-column"
import CountryTabs from '../../../components/country-tabs'
import Columnar from '../../../components/columnar'
import FindOutMoreCTA from '../../../components/find-out-more-cta'

const bannerContent = {
  title: "Unlock a world of trade whilst staying legally compliant.",
  description: [
    {
      text: "Taxes and compliance are probably the biggest barrier to your business going global - with so many rules, you don't want to put a foot out of line - but you do want to capture global opportunities. Billsby is your solution."
    }
  ],
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up today'
    },
    {
      scheduleButton: true,
      buttonColor: "black"
    }
  ],
  // sectionDevelopers: true,
  sectionFeaturetag: true,
  sectionSubscriberBanner: true,
  svg: true,
  image: require('../../../images/salestax-compliance.svg'),
  imageName: 'salestax-compliance svg',
  backgroundColor: '#f3f5fb',
  textColor: '#252422',
  imagePosition: 'right'
}

const countryTabsContent = {
  title: 'Truly global tax compliance with specially designed solutions',
  tabs: [
    {
      tabTitle: 'United States',
      svg: true,
      image: require('../../../images/billsbot-usa.svg'),
      tabsContent: [
        {
          list: [
            {
              description: "Some countries have well designed sales tax regimes that minimise the burden to both businesses and consumers. The United States is a lot of things - but it isn't one of those countries.",
            },
            {
              description: "There are more than 10,000 possible sales tax rate combinations. In fact, a pair of Mickey ears costs a different amount at one of Disney's hotels than the theme park because even they're in two different sales tax zones!",
            },
            {
              description: "With nexus rules to worry about on top, it's a dizzying array of complexity and confusion that can leave you in legal trouble if you don't figure it all out. And don't think it's simple because you only operate out of one state - if you use services like fulfilment by Amazon, you can have a nexus in places you've never set foot in before. And the rules keep changing every year.",
            }
          ]
        },
        {
          list: [
            {
              description: <>Billsby offers two solutions. Through our integration with TaxJar, you can automatically calculate sales taxes across all fifty states. They'll even help you by filing your taxes for you and making sure each state is paid on the required schedule - and figuring out all of the nexus rules. We think it's absolutely worth the <a href="https://www.taxjar.com/" target="_blank" rel="noopener noreferrer">$19 per month they charge.</a></>
            },
            {
              description: "Or if you're just starting out and you've got relatively simple tax needs we can calculate your taxes for you with Automatic Tax Calculations by Billsby and then show you the results in your global taxes dashboard. There's a charge of 2 cents per tax calculation for this service.",
            },
          ],
          btnText: 'Sales Tax in the United States',
          btnClass: 'adoric_video_ustaxes',
          isButton: true
        }
      ]
    },
    {
      tabTitle: 'Canada',
      svg: true,
      image: require('../../../images/billsbot-canada.svg'),
      tabsContent: [
        {
          list: [
            {
              description: "Sales tax for Canadian businesses, or businesses wanting to sell products to Canadian consumers can cause some serious ‘hair pulling’ frustration. In general, Canada levies a federal goods and services tax, GST, across all of its provinces. Seems pretty straight forward right? Wrong, it doesn’t stop there.",
            },
            {
              description: "In addition to GST, every province (except Alberta) levies a provincial sales tax called PST. In British Columbia and Saskatchewan these rates are applied separately. In five other of Canada’s provinces, however, these tax rates are combined differently to create what is known as the harmonised sales tax, or HST for short. So three possible tax rates, that’s not too bad! Well unfortunately, Canada has 10 provinces to account for.",
            },
            {
              description: "This is where QST and RST comes in. In Quebec, the provincial sales tax rate differs and is therefore known as Quebec sales tax, QST. Alongside Quebec, Manitoba also has a provincial sales tax that differs known as retail sales tax, or RST. Keeping up so far? Well sadly territories also have their own rules!",
            }
          ]
        },
        {
          list: [
            {
              description: "With Canada following what is called the ‘place of supply rule’, the province or territory in which you conduct business with your customer determines the combination of tax rates applied. But keeping on top of this is not an easy task, especially when policies, rates or combinations change.",
            },
            {
              description: <>Billsby offers two solutions. Through our integration with TaxJar, you can automatically calculate sales taxes across all provinces and territories. They'll even help you by filing your taxes and automatically taking into account any policy changes. We think it's absolutely worth the <a href="https://www.taxjar.com/" target="_blank" rel="noopener noreferrer">$19 per month they charge.</a></>
            },
            {
              description: "Or if you're just starting out and you've got relatively simple tax needs we can calculate your taxes for you with Automatic Tax Calculations by Billsby and then show you the results in your global taxes dashboard. There's a charge of 2 cents per tax calculation for this service.",
            },
          ],
          // btnText: 'Sales Tax in Canada'
        }
      ]
    },
    {
      tabTitle: 'European Union',
      svg: true,
      image: require('../../../images/billsbot-eu.svg'),
      tabsContent: [
        {
          list: [
            {
              description: "With a population of more than 500,000,000 spanned over various member states and transacting different currencies, tax compliance in the European Union can seem overwhelming.",
            },
            {
              description: "Not only does the EU span across multiple countries, but its value added tax rate (VAT) interacts with businesses in a different way to sales tax. With sales tax, the ultimate financial burden of taxation falls only on the final consumer. In the world of VAT, however, every party in the supply chain must pay a portion of the tax burden. Making it not only legally, but financially potent for your business to configure taxes for the EU correctly.",
            }
          ]
        },
        {
          list: [
            {
              description: <>Billsby offers a solution.</>
            },
            {
              description: "You can configure your taxes for the EU. When manually configuring your taxes you’ll be required to provide some additional information, including your VAT MOSS registration number if this applies to you. Any policy changes will not be automatically taken into account if you manually configure your taxes for the EU.",
            },
            {
              description: "You’ll be able to determine whether you’d like your customers location or VAT number validating and you’ll even be able to generate a VIES VAT consultation number if required.",
            }
          ],
          // btnText: 'Sales Tax in European Union'
        },
      ]
    },
    {
      tabTitle: 'Australia',
      svg: true,
      image: require('../../../images/billsbot-australia.svg'),
      tabsContent: [
        {
          list: [
            {
              description: "For companies with a commercial presence in Australia, and a gross income that’s over AUD $75,000 without tax, goods and services tax (GST) must be levied on all physical and digital goods that are traded for consumption in Australia.",
            },
            {
              description: "Since July 1, 2018 all out-of-country online retailers must also collect GST on all products sold for below the low value threshold to Australian consumers. For luxury goods above the threshold, GST is charged and collected at the border instead. However, determining whether your products are low value or luxury goods can be messy and, if done incorrectly, can cause your customers to incur hefty unexpected charges.",
            }
          ]
        },
        {
          list: [
            {
              description: <>Billsby offers a solution.</>
            },
            {
              description: "You can manually configure your taxes for Australia. When manually configuring your taxes you’ll be required to determine the tax rates you’d like your Australian consumers to be charged, and label these accordingly. Any policy changes will not be automatically taken into account if you manually configure your taxes for Australia.",
            },
            {
              description: "You’ll be able to determine whether you’d like your customers location or ABN number validating during checkout.",
            },
          ],
          // btnText: 'Sales Tax in Australia'
        }
      ]
    },
    {
      tabTitle: 'New Zealand',
      svg: true,
      image: require('../../../images/billsbot-nz.svg'),
      tabsContent: [
        {
          list: [
            {
              description: "For companies with a commercial presence in New Zealand, and a gross income that’s over NZ $60,000 without tax, goods and services tax (GST) must be levied on all physical and digital goods that are traded for consumption in Australia.",
            },
            {
              description: "Since December 1, 2019 all out-of-country online retailers must also collect GST on all products sold for below the low value threshold to New Zealand consumers. For luxury goods above this threshold, GST is charged and collected at the border instead. It’s therefore important that you successfully determine which category your products fall into, because if done incorrectly this can cause your customers to incur hefty unexpected charges.",
            }
          ]
        },
        {
          list: [
            {
              description: "Taxes must be manually configured for New Zealand. When manually configuring your taxes you’ll be required to determine the tax rates you’d like your New Zealand consumers to be charged, and label these accordingly. You’ll also be able to determine whether you’d like your customers location or NZBN number validating during checkout. With manually configured tax rates, any policy changes will not be automatically updated in Billsby.",
            }
          ],
          // btnText: 'Sales Tax in New Zealand'
        }
      ]
    },
    {
      tabTitle: 'India',
      svg: true,
      image: require('../../../images/billsbot-india.svg'),
      tabsContent: [
        {
          list: [
            {
              description: "Taxes in India are a fiscal nightmare. With over 35 different tax rates to consider based on the state and classification of good and services being sold, it can be a real challenge getting it right.",
            },
            {
              description: "Broadly speaking, India’s GST is classified into a central goods and services tax (CGST), state/union territory goods and services tax (SGST/UTGST) and integrated goods and services tax (IGST). Customers will then be charged a tax rate based on their location and the location of your business. It seems pretty straight forward, but it can cause a headache trying to account for the different rates and combinations. This is where Billsby can help you.",
            }
          ]
        },
        {
          list: [
            {
              description: "Taxes must be manually configured for India. When manually configuring your taxes you’ll be asked to provide a tax rate for CGST, SGST/UTGST and Cess where applicable. We will then use your CGST and SGST/UTGST rates to calculate IGST, so you will not need to provide this separately. These rates, along with the location of your business and your customer will be used to apply a tax to your customers purchases. You’ll also be able to override rules for specific states, if needed. With manually configured tax rates, any policy changes will not be automatically updated in Billsby.",
            },
          ],
          // btnText: 'Sales Tax in India'
        }
      ]
    },
    {
      tabTitle: 'Rest of World',
      svg: true,
      image: require('../../../images/billsbot-row.svg'),
      tabsContent: [
        {
          list: [
            {
              description: "Thankfully, the rest of the world isn’t as complicated as the US, Canada, EU, Australia, New Zealand and India - but with over 190 countries spanning the globe, Billsby supports customer tax rates worldwide.",
            },
            {
              description: "Plus, our product team are always monitoring developing regulatory frameworks, and identifying opportunities to simplify tax processing for you in the future elsewhere in the world, too.",
            }
          ]
        },
        {
          list: [
            {
              description: "You can manually define what tax rates you’d like to charge for each country where you do business, and we’ll keep track of what you owe. With all manually configured tax rates, any policy changes will not be automatically updated in Billsby.",
            }
          ],
          // btnText: 'Sales Tax in the Rest of World'
        }
      ]
    },
  ]
}

const taxReportContent = {
  title: "See all your tax calculations in your global taxation report.",
  description: [
    {
      text: "If you're selling in different countries, you'll probably want to take a pick and mix approach to tax calculation - using our incredible TaxJar integration for some countries whilst manually calculating and applying taxes elesewhere."
    },
    {
      text: "Our global taxation report will show you the result of all these separate efforts in one place - so you can see how much you're paying in taxes world over."
    }
  ],
  image: 'sales-tax-and-compliance-report.png',
  imageName: 'salestax-compliance',
  backgroundImage: require('../../../images/sales-tax-and-compliance-report@2x.png'),
  backgroundColor: '#243f5c',
  textColor: '#fff',
  imagePosition: 'full-right',
  sectionReport: true
}

const columnarContent = [
  {
    title: "Payment industry compliance is done for you, too.",
    list: [
      {
        title: 'Reduce your PCI-DSS compliance burden',
        description: "With Billsby, cards are securely tokenised in the Billsby Vault - whichever integration approach you choose you'll never be in possession of a customers payment information. This keeps your PCI-DSS compliance burden as low as possible, with quick and simple implementation.",
      },
      {
        title: 'Get ready for SCA',
        description: "SCA, or Secure Card Authentication, is the next big piece of legislation that'll affect your card payments and subscription customers. If you're using Billsby Checkout, SCA compliance will be automatically enabled on your website as it becomes necessary with no development work required.",
      }
    ]
  },
  {
    title: "And GDPR? We've sorted that for you as well.",
    list: [
      {
        title: 'Our DPA is waiting for your signature',
        description: "We've written a model DPA that's signature ready - so there's no need to individually negotiate a DPA with us. And we list all of our processors clearly in the privacy section of our website and keep things up-to-date, helping you to stay on the right side of the law.",
      },
      {
        title: 'GDPR-as-a-feature',
        description: <>GDPR was built into our platform from day one - so you can purge our customer records in one click from your control panel and satisfy the right to be forgotten, and use features like <Link to="/product/integrations/planactions">Plan Actions</Link>, <Link to="/product/integrations/zapier">Zapier</Link> and our <Link to="/product/integrations/api-and-web-hooks">API</Link> to keep all of your systems synchronised and records up-to-date.</>,
      },
    ]
  }
]

const findOutMoreCTAContent = {
  title: "Our compliance promise",
  description: "Right now, somewhere in the world, some politicians are coming up with a well meaning but possibly difficult to implement law. At Billsby, we'll always have your back and we'll always work with you to help you comply.",
  url: "/pro/development",
  backgroundColor: '#F3F5FB',
  buttons: [
    {
      buttonText: "Sign up today",
      buttonColor: "orange",
      url: 'https://app.billsby.com/registration',
      isExternal: true
    },
    {
      scheduleButton: true,
      buttonColor: "black"
    }
  ]
}

const SalesTaxAndCompliance = () => (
  <Layout className="subscriber">
    <SEO 
      title="Globally tax compliant subscription billing | Billsby" 
      description="Global tax compliance solution with the best recurring billing & subscription management software. Check tax calculations and global taxation reports | Billsby" 
      url="https://www.billsby.com/product/subscribers/sales-tax-and-compliance"
    />

    <TwoColumn content={bannerContent}/>
    <CountryTabs content={countryTabsContent}/>
    <TwoColumn content={taxReportContent}/>
    <div className="section-payment-industry-compliance">
      <Columnar content={columnarContent} />
    </div>
    <FindOutMoreCTA content={findOutMoreCTAContent} />
  </Layout>
)

export default SalesTaxAndCompliance
